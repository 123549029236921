import React from "react"
import Layout from "../components/layout"
import Content from "../components/content"
import {Link} from "gatsby"


export default () => 
        <Layout>
            <Content>
                <h1>Roses are red, apples are round</h1>
                <p>404 this page is not found</p>
            </Content>
        </Layout>

